/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/productManagement/model',
    name: 'productModel',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      title: '金融产品模型'
    },
    component: () => import('@/views/productManagement/model/list')
  },
  {
    path: '/productManagement/model/detail',
    name: 'modelDetail',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      parentName: 'productModel',
      title: ''
    },
    component: () => import('@/views/productManagement/model/edit')
  },
  {
    path: '/productManagement/model/:editMode',
    name: 'modelEdit',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      parentName: 'productModel',
      title: ''
    },
    component: () => import('@/views/productManagement/model/edit')
  },
  {
    path: '/productManagement/product',
    name: 'product',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      title: '金融产品'
    },
    component: () => import('@/views/productManagement/product/list')
  },
  {
    path: '/productManagement/product/detail',
    name: 'productDetail',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      parentName: 'product',
      title: '详情'
    },
    component: () => import('@/views/productManagement/product/edit')
  },
  {
    path: '/productManagement/product/historyDetail',
    name: 'productHistoryDetail',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      parentName: 'product',
      title: '历史详情'
    },
    component: () => import('@/views/productManagement/product/historyDetail')
  },
  {
    path: '/productManagement/product/:editMode',
    name: 'productEdit',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      parentName: 'product',
      title: ''
    },
    component: () => import('@/views/productManagement/product/edit')
  },
  {
    path: '/productManagement/sceneSide',
    name: 'productSceneSide',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      title: '场景方金融产品'
    },
    component: () => import('@/views/productManagement/sceneSide/list')
  },
  {
    path: '/productManagement/sceneSide/detail',
    name: 'productSceneSideDetail',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      parentName: 'productSceneSide',
      title: '详情'
    },
    component: () => import('@/views/productManagement/sceneSide/edit')
  },
  {
    path: '/productManagement/sceneSide/historyDetail',
    name: 'productSceneHistoryDetail',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      parentName: 'productSceneSide',
      title: '历史详情'
    },
    component: () => import('@/views/productManagement/sceneSide/historyDetail')
  },
  {
    path: '/productManagement/sceneSide/:editMode',
    name: 'productSceneSideEdit',
    meta: {
      requireAuth: false,
      group: '金融产品管理',
      parentName: 'productSceneSide',
      title: ''
    },
    component: () => import('@/views/productManagement/sceneSide/edit')
  }
]
