/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/companyManagement/channel',
    name: 'channel',
    meta: {
      requireAuth: false,
      group: '企业管理',
      title: '运营方列表'
    },
    component: () => import('@/views/companyManagement/channel/list')
  },
  {
    path: '/companyManagement/channel/detail',
    name: 'channelDetail',
    meta: {
      requireAuth: false,
      group: '企业管理',
      parentName: 'channel',
      title: '详情'
    },
    component: () => import('@/views/companyManagement/channel/edit')
  },
  {
    path: '/companyManagement/channel/:editMode',
    name: 'channelEdit',
    meta: {
      requireAuth: false,
      group: '企业管理',
      parentName: 'channel',
      title: ''
    },
    component: () => import('@/views/companyManagement/channel/edit')
  },
  {
    path: '/companyManagement/capital',
    name: 'capital',
    meta: {
      requireAuth: false,
      group: '企业管理',
      title: '资金方列表'
    },
    component: () => import('@/views/companyManagement/capital/list')
  },
  {
    path: '/companyManagement/capital/detail',
    name: 'capitalDetail',
    meta: {
      requireAuth: false,
      group: '企业管理',
      parentName: 'capital',
      title: '详情'
    },
    component: () => import('@/views/companyManagement/capital/detail')
  },
  {
    path: '/companyManagement/capital/:editMode',
    name: 'capitalEdit',
    meta: {
      requireAuth: false,
      group: '企业管理',
      parentName: 'capital',
      title: ''
    },
    component: () => import('@/views/companyManagement/capital/edit')
  },
  {
    path: '/companyManagement/core',
    name: 'core',
    meta: {
      requireAuth: false,
      group: '企业管理',
      title: '核心企业列表'
    },
    component: () => import('@/views/companyManagement/core/list')
  },
  {
    path: '/companyManagement/core/detail',
    name: 'coreDetail',
    meta: {
      requireAuth: false,
      group: '企业管理',
      parentName: 'core',
      title: ''
    },
    component: () => import('@/views/companyManagement/core/edit')
  },
  {
    path: '/companyManagement/core/cert',
    name: 'coreCert',
    meta: {
      requireAuth: false,
      group: '企业管理',
      parentName: 'core',
      title: '数字证书配置'
    },
    component: () => import('@/views/companyManagement/core/cert')
  },
  {
    path: '/companyManagement/core/:editMode',
    name: 'coreEdit',
    meta: {
      requireAuth: false,
      group: '企业管理',
      parentName: 'core',
      title: ''
    },
    component: () => import('@/views/companyManagement/core/edit')
  },
  {
    path: '/companyManagement/customer',
    name: 'customer',
    meta: {
      requireAuth: false,
      group: '企业管理',
      title: '融资企业列表'
    },
    component: () => import('@/views/companyManagement/customer/list')
  },
  {
    path: '/companyManagement/customer/detail',
    name: 'customerDetail',
    meta: {
      requireAuth: false,
      group: '企业管理',
      parentName: 'customer',
      title: '详情'
    },
    component: () => import('@/views/companyManagement/customer/edit')
  },
  {
    path: '/companyManagement/customer/:editMode',
    name: 'customerEdit',
    meta: {
      requireAuth: false,
      group: '企业管理',
      parentName: 'customer',
      title: ''
    },
    component: () => import('@/views/companyManagement/customer/edit')
  }
]
