/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/assetsManagement/contract',
    name: 'assetsContract',
    meta: {
      requireAuth: false,
      group: '资产管理',
      title: '基础合同管理'
    },
    component: () => import('@/views/assetsManagement/contract/list')
  },
  {
    path: '/assetsManagement/contract/detail',
    name: 'assetsContractDetail',
    meta: {
      requireAuth: false,
      group: '资产管理',
      parentName: 'assetsContract',
      title: '详情'
    },
    component: () => import('@/views/assetsManagement/contract/edit')
  },
  {
    path: '/assetsManagement/receivable',
    name: 'assetsReceivable',
    meta: {
      requireAuth: false,
      group: '资产管理',
      title: '应收账款管理'
    },
    component: () => import('@/views/assetsManagement/receivable/list')
  },
  {
    path: '/assetsManagement/receivable/detail',
    name: 'assetsReceivableDetail',
    meta: {
      requireAuth: false,
      group: '资产管理',
      parentName: 'assetsReceivable',
      title: '详情'
    },
    component: () => import('@/views/assetsManagement/receivable/edit')
  },
  {
    path: '/assetsManagement/invoice',
    name: 'assetsInvoice',
    meta: {
      requireAuth: false,
      group: '资产管理',
      title: '发票管理'
    },
    component: () => import('@/views/assetsManagement/invoice/list')
  }
]
