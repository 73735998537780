<!-- 404 -->
<template>
  <div class="not-found-page">
    <h1>404</h1>
    <p>页面没有找到</p>
    <p>
      <router-link to="/index">
        返回首页
      </router-link>
    </p>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
  metaInfo: {
    title: '404'
  },
  components: {},
  data () {
    return {}
  },
  watch: {},
  created () {

  },
  methods: {}
}
</script>
<style lang="less">
.not-found-page {
  h1{
    margin-top: 100px;
    font-size: 60px;
    text-align: center
  }
  p {
    font-size: 24px;
    text-align: center;
  }
  a {
    font-size: 18px;
  }
}
</style>
