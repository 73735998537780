/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/workbench/task',
    name: 'task',
    meta: {
      requireAuth: false,
      group: '工作台',
      title: '我的任务'
    },
    component: () => import('@/views/workbench/task/myTaskList')
  },
  {
    path: '/workbench/message',
    name: 'message',
    meta: {
      requireAuth: false,
      group: '工作台',
      title: '消息列表'
    },
    component: () => import('@/views/workbench/message/list')
  },
  {
    path: '/workbench/message/detail',
    name: 'messageDetail',
    meta: {
      requireAuth: false,
      group: '工作台',
      parentName: 'message',
      title: '详情'
    },
    component: () => import('@/views/workbench/message/detail')
  },
  {
    path: '/workbench/dashboard',
    name: 'dashboard',
    meta: {
      requireAuth: false,
      group: '工作台',
      title: '仪表盘'
    },
    component: () => import('@/views/workbench/dashboard/index')
  },
  {
    path: '/workbench/quota',
    name: 'quota',
    meta: {
      requireAuth: false,
      group: '工作台',
      title: '额度汇总'
    },
    component: () => import('@/views/workbench/quota/index')
  },
]
