/**
 *  配置信息
 */
import Cookies from 'js-cookie'
import tld from 'tldjs'
// 将 localhost 设置为合法的域名
const tldjs = tld.fromUserSettings({
  validHosts: ['localhost']
})

const profiles = {
  // 环境变量
  env: () => process.env.NODE_ENV,
  /**
   * cookie储存域判断
   * ip地址返回ip
   * 域名返回主域名
   * localhost 返回 localhost
   * @returns {*|string} cookie储存域
   */
  cookieDomain: () => {
    let domain = ''
    const r = /^((25[0-5]|2[0-4]\d|[01]?\d\d?)($|(?!\.$)\.)){4}$/ // 判断是否为IP地址
    // 首先确定是否为ip
    if (r.test(document.domain)) {
      domain = document.domain
    } else {
      domain = tldjs.getDomain(document.domain)
    }
    return domain
  },
  consoleDomain: () => {
    return '/login'
  },
  appKey: () => {
    return ''
  }
}
const cookie = {
  token: () => {
    return Cookies.get(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'token')
  },
  empAccount: () => {
    return Cookies.get(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'empAccount')
  },
  empMobile: () => {
    return Cookies.get(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'empMobile')
  },
  empName: () => {
    return Cookies.get(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'empName')
  },
  empCode: () => {
    return Cookies.get(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'empCode')
  },
  appKey: () => {
    return Cookies.get(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'appKey')
  },
  visitAt: () => {
    return Cookies.get(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'visitAt')
  },
  roleList: () => {
    return Cookies.get(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'roleList')
  },
  globalLogo: () => {
    return Cookies.get(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'globalLogo')
  },
  globalWangName: () => {
    return Cookies.get(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'globalWangName')
  },
  setCookie: (cookieName, value, remember = false) => {
    if (remember) {
      Cookies.set(process.env.VUE_APP_COOKIES_PREFIX + '_' + cookieName, value, { expires: 30, domain: profiles.cookieDomain(), path: '/' })
    } else {
      Cookies.set(process.env.VUE_APP_COOKIES_PREFIX + '_' + cookieName, value, { domain: profiles.cookieDomain(), path: '/' })
    }
  },
  removeCookie: (cookieName) => {
    if (cookieName) {
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + cookieName)
    } else if (cookieName === 'roleList') {
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'roleList')
    } else {
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'token')
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'empAccount')
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'empMobile')
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'empName')
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'empCode')
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'appKey')
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'visitAt')
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'globalLogo')
      Cookies.remove(process.env.VUE_APP_COOKIES_PREFIX + '_' + 'globalWangName')

    }
  }
}
export { profiles, cookie }
