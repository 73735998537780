import api from './api'
let CryptoAgent = ''
let source = ''
let requestUrl = ''
const cfca = {
  dataHunter (val, url) {
    source = val
    requestUrl = url
    this.OnLoad()
  },
  OnLoad () {
    try {
      var eDiv = document.createElement("div");
      if (navigator.appName.indexOf("Internet") >= 0 || navigator.appVersion.indexOf("Trident") >= 0) {
        if (window.navigator.cpuClass == "x86") {
          eDiv.innerHTML = "<object id=\"CryptoAgent\" codebase=\"CryptoKit.CFCPNGM.x86.cab\" classid=\"clsid:C1882951-364C-4873-8AB6-2471ABB9D29A\" ></object>";
        }
        else {
          eDiv.innerHTML = "<object id=\"CryptoAgent\" codebase=\"CryptoKit.CFCPNGM.x64.cab\" classid=\"clsid:6D2242DA-C597-4542-99B3-D4C770531837\" ></object>";
        }
      }
      else {
        alert("Only support IE!");
      }
      document.body.appendChild(eDiv);
    }
    catch (e) {
      alert(e);
      return;
    }
    CryptoAgent = document.getElementById("CryptoAgent");
    this.SetSM2CSPList()
  },
  SetSM2CSPList () {
    let cspList = 'Uyee CSP v6.0 (Enterprise)'
    let bResult = CryptoAgent.SetSM2CSPList(cspList)
    // console.log('SetSM2CSPList: ' + bResult)
    this.SelectCertificateOnClick()
  },
  SelectCertificateOnClick () {
    try {
      let subjectDNFilter = ''
      let issuerDNFilter = ''
      let serialNumFilter = ''
      let bSelectCertResult = ''
      bSelectCertResult = CryptoAgent.SelectCertificate(subjectDNFilter, issuerDNFilter, serialNumFilter);
      // Opera浏览器，NPAPI函数执行结果为false时，不能触发异常，需要自己判断返回值。
      if (!bSelectCertResult) {
        let errorDesc = CryptoAgent.GetLastErrorDesc();
        // console.log(errorDesc)
        alert(errorDesc);
        return;
      }
      // console.log('bSelectCertResult',bSelectCertResult)
      this.GetCertInfoOnClick()
    } catch (e) {
      let errorDesc = CryptoAgent.GetLastErrorDesc();
      // console.log(errorDesc)
      alert(errorDesc);
    }
  },
  GetCertInfoOnClick() {
    try {
      let InfoTypeID = 'SubjectDN'
      let InfoContent = ''
      InfoContent = CryptoAgent.GetSignCertInfo(InfoTypeID);
      // Opera浏览器，NPAPI函数执行结果为false时，不能触发异常，需要自己判断返回值。
      if (!InfoContent) {
        let errorDesc = CryptoAgent.GetLastErrorDesc();
        alert(errorDesc);
        return
      }
      // console.log('InfoContent',InfoContent)
      this.SignOnClick()
    } catch (e) {
      let errorDesc = CryptoAgent.GetLastErrorDesc();
      alert(errorDesc);
    }
  },
  SignOnClick () {
    let signature = ''
    // source 签名原文
    //const source = '123456abc'
    // sign type
    // certificate information identifier
    let signType = 'PKCS#1'
    let selectedAlg = 'SHA-1'
    if (signType === 'Attach') {
      // PKCS#7 Attach
      // console.log('Attach', source)
      signature = CryptoAgent.SignMsgPKCS7(source, selectedAlg, true);
    } else if (signType === 'Detach') {
      // PKCS#7 Detach
      // console.log('Detach', source)
      signature = CryptoAgent.SignMsgPKCS7(source, selectedAlg, false);
    } else {
      // PKCS#1
      // console.log('PKCS#1', source)
      signature = CryptoAgent.SignMsgPKCS1(source, selectedAlg);
    }
    if (!signature) {
      let errorDesc = CryptoAgent.GetLastErrorDesc();
      alert(errorDesc);
      return;
    }
    api.open.verifyPKCS7({ sign: signature, requestUrl: requestUrl }).then(result => {
      alert('身份认证通过,请重新提交');
      signature = ''
    }).catch(e => {
      signature = ''
    })
  },
  // Sign hash message
  SignP1OnClick () {
    let signature = ''
    // source 签名原文
    //const source = '123456abc'
    // sign type
    // certificate information identifier
    let signType = 'PKCS#1'
    let selectedAlg = 'SHA-1'
    try {
      signature = CryptoAgent.SignMsgPKCS1(source, selectedAlg);
      if (!signature) {
        let errorDesc = CryptoAgent.GetLastErrorDesc();
        alert(errorDesc);
        return;
      }
      api.open.verifyPKCS7({ sign: signature, requestUrl: requestUrl }).then(result => {
        alert('身份认证通过,请重新提交');
        signature = ''
      }).catch(e => {
        signature = ''
      })
    } catch (e) {
      let errorDesc = CryptoAgent.GetLastErrorDesc();
      alert(errorDesc);
    }
  }
}
export default cfca
