/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/riskManagement/black',
    name: 'black',
    meta: {
      requireAuth: false,
      group: '风控管理',
      title: '黑名单管理'
    },
    component: () => import('@/views/riskManagement/black/list')
  },
  {
    path: '/riskManagement/white',
    name: 'white',
    meta: {
      requireAuth: false,
      group: '风控管理',
      title: '白名单管理'
    },
    component: () => import('@/views/riskManagement/white/list')
  }
]
