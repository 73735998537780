var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "not-found-page" }, [
    _c("h1", [_vm._v("404")]),
    _c("p", [_vm._v("页面没有找到")]),
    _c(
      "p",
      [_c("router-link", { attrs: { to: "/index" } }, [_vm._v(" 返回首页 ")])],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }