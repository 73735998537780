/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/creditManagement',
    name: 'credit',
    meta: {
      requireAuth: false,
      group: '授信管理',
      title: '授信列表'
    },
    component: () => import('@/views/creditManagement/list')
  },
  {
    path: '/creditManagement/detail',
    name: 'creditDetail',
    meta: {
      requireAuth: false,
      group: '授信管理',
      parentName: 'credit',
      title: '详情'
    },
    component: () => import('@/views/creditManagement/detail')
  },
  {
    path: '/creditManagement/supplement',
    name: 'creditSupplement',
    meta: {
      requireAuth: false,
      group: '授信管理',
      parentName: 'credit',
      title: '补件'
    },
    component: () => import('@/views/creditManagement/supplement')
  },
  {
    path: '/creditManagement/audit',
    name: 'creditAudit',
    meta: {
      requireAuth: false,
      group: '授信管理',
      parentName: 'credit',
      title: '审批'
    },
    component: () => import('@/views/creditManagement/audit')
  }
]
