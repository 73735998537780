var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "default-layout", attrs: { direction: "vertical" } },
    [
      _c("el-header", { staticClass: "header", attrs: { height: "48px" } }, [
        _c("h1", { staticClass: "logo" }, [
          _c("a", { staticClass: "logo-title", attrs: { href: "/index" } })
        ]),
        _c(
          "div",
          {
            staticClass: "logo-subtitle",
            staticStyle: { "margin-top": "-5px" }
          },
          [
            _c("router-link", { attrs: { to: "/index" } }, [
              _vm._v(" " + _vm._s(_vm.projectTitle) + " ")
            ])
          ],
          1
        ),
        _c("div", { attrs: { name: "account" } }, [
          _c("div", { staticClass: "account" }, [
            _c("span", { staticClass: "welcome-msg" }, [
              _vm._v("您好！" + _vm._s(_vm.empName))
            ]),
            _c(
              "span",
              { staticStyle: { "padding-left": "0px" } },
              [
                _c(
                  "a",
                  {
                    staticClass: "account-link",
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.moreMessage }
                  },
                  [
                    _c("span", [
                      _c("i", { staticClass: "el-icon-bell" }),
                      _vm._v("消息")
                    ])
                  ]
                ),
                _vm.$store.state.quantity > 0
                  ? _c("el-badge", {
                      staticClass: "item",
                      attrs: { value: _vm.$store.state.quantity, max: 99 }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "a",
              {
                staticClass: "account-link",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    return _vm.$router.push({ path: "/personal" })
                  }
                }
              },
              [
                _c("span", [
                  _c("i", { staticClass: "el-icon-user" }),
                  _vm._v("个人中心")
                ])
              ]
            ),
            _c(
              "a",
              {
                staticClass: "account-link",
                attrs: { href: "javascript:;" },
                on: { click: _vm.logout }
              },
              [_c("span", [_vm._v("退出")])]
            )
          ])
        ])
      ]),
      _c(
        "el-container",
        { staticClass: "main-container" },
        [
          _c("page-side", {
            attrs: { "side-menu": this.$store.state.sideMenu }
          }),
          _c("router-view")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }