<template>
  <div id="app" class="normal">
    <router-view />
  </div>
</template>
<script>
import {cookie} from "./assets/js/config";

export default {
  name: 'App',
  metaInfo () {
    return {
      title: this.$route.meta.title,
      link: [
        { rel: 'icon', href: cookie.globalLogo()}
      ],
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - ${cookie.globalWangName()}` : cookie.globalWangName()
      }
    }
  },
  components: {
  },
  data () {
    return {
      title: '首页'
    }
  },
  created () {
  }
}
</script>
<style lang="less">
</style>
