var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("img", {
        attrs: { src: require("../assets/images/401.png"), alt: "" }
      }),
      _c("p", [_vm._v("无权访问该页面，")]),
      _c("p", [_vm._v("如需权限请联系管理员")]),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.goIndex } },
        [_vm._v(" 返回首页 ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }