/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/admittanceManagement',
    name: 'admittance',
    meta: {
      requireAuth: false,
      group: '准入管理',
      title: '企业准入'
    },
    component: () => import('@/views/admittanceManagement/list')
  },
  {
    path: '/admittanceManagement/agencyAccess',
    name: 'agencyAccess',
    meta: {
      requireAuth: false,
      group: '准入管理',
      parentName: 'admittance',
      title: ''
    },
    component: () => import('@/views/admittanceManagement/agencyAccess')
  },
  {
    path: '/admittanceManagement/supplement',
    name: 'admittanceSupplement',
    meta: {
      requireAuth: false,
      group: '准入管理',
      parentName: 'admittance',
      title: ''
    },
    component: () => import('@/views/admittanceManagement/supplement')
  },
  {
    path: '/admittanceManagement/audit',
    name: 'admittanceAudit',
    meta: {
      requireAuth: false,
      group: '准入管理',
      parentName: 'admittance',
      title: ''
    },
    component: () => import('@/views/admittanceManagement/detail')
  },
  {
    path: '/admittanceManagement/detail',
    name: 'admittanceDetail',
    meta: {
      requireAuth: false,
      group: '准入管理',
      parentName: 'admittance',
      title: ''
    },
    component: () => import('@/views/admittanceManagement/detail')
  },
  {
    path: '/admittanceManagement/:editMode',
    name: 'admittanceEdit',
    meta: {
      requireAuth: false,
      group: '准入管理',
      parentName: 'admittance',
      title: ''
    },
    component: () => import('@/views/admittanceManagement/edit')
  }
]
