/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/useManagement',
    name: 'use',
    meta: {
      requireAuth: false,
      group: '用信管理',
      title: '用信列表'
    },
    component: () => import('@/views/useManagement/list')
  },
  {
    path: '/useManagement/supplement',
    name: 'useSupplement',
    meta: {
      requireAuth: false,
      group: '用信管理',
      parentName: 'use',
      title: '补件'
    },
    component: () => import('@/views/useManagement/supplement')
  },
  {
    path: '/useManagement/detail',
    name: 'useDetail',
    meta: {
      requireAuth: false,
      group: '用信管理',
      parentName: 'use',
      title: '详情'
    },
    component: () => import('@/views/useManagement/detail')
  },
  {
    path: '/useManagement/useCoreAudit',
    name: 'useCoreAudit',
    meta: {
      requireAuth: false,
      group: '用信管理',
      parentName: 'use',
      title: '审批'
    },
    component: () => import('@/views/useManagement/useCoreAudit')
  }
]
