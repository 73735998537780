/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/accountManagement/accountInfo',
    name: 'account',
    meta: {
      requireAuth: false,
      group: '电子账薄管理',
      title: '电子账薄'
    },
    component: () => import('@/views/accountManagement/accountInfo/index')
  },
  {
    path: '/accountManagement/accountInfo/detail',
    name: 'accountDetail',
    meta: {
      requireAuth: false,
      group: '电子账薄管理',
      parentName: 'account',
      title: '详情'
    },
    component: () => import('@/views/accountManagement/accountInfo/detail')
  },
  {
    path: '/accountManagement/accountInfo/bindingEdit',
    name: 'bindingBankEdit',
    meta: {
      requireAuth: false,
      group: '电子账薄管理',
      parentName: 'account',
      title: '绑定银行卡'
    },
    component: () => import('@/views/accountManagement/accountInfo/binding-bank')
  },
  {
    path: '/systemManagement/account/binding1Edit',
    name: 'bindingBank1Edit',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'account',
      title: '绑定银行卡'
    },
    component: () => import('@/views/accountManagement/accountInfo/binding-bank1')
  },
  {
    path: '/accountManagement/accountInfo/verificationOrPay',
    name: 'verificationOrPay',
    meta: {
      requireAuth: false,
      group: '电子账薄管理',
      parentName: 'account',
      title: '验证'
    },
    component: () => import('@/views/accountManagement/accountInfo/verification-or-pay')
  },
  {
    path: '/accountManagement/accountInfo/verificationOrPay1',
    name: 'verificationOrPay1',
    meta: {
      requireAuth: false,
      group: '电子账薄管理',
      parentName: 'account',
      title: '验证'
    },
    component: () => import('@/views/accountManagement/accountInfo/verification-or-pay1')
  },
  {
    path: '/accountManagement/accountInfo/waitFor',
    name: 'waitFor',
    meta: {
      requireAuth: false,
      group: '电子账薄管理',
      parentName: 'account',
      title: '等待中'
    },
    component: () => import('@/views/accountManagement/accountInfo/wait-for')
  },
  {
    path: '/accountManagement/accountInfo/:editMode',
    name: 'accountEdit',
    meta: {
      requireAuth: false,
      group: '电子账薄管理',
      parentName: 'account',
      title: ''
    },
    component: () => import('@/views/accountManagement/accountInfo/edit')
  },
  {
    path: '/accountManagement/accountList',
    name: 'accountList',
    meta: {
      requireAuth: false,
      group: '电子账薄管理',
      title: '电子账薄列表'
    },
    component: () => import('@/views/accountManagement/accountList/list')
  },
  {
    path: '/accountManagement/accountList/detail',
    name: 'accountListDetail',
    meta: {
      requireAuth: false,
      group: '电子账薄管理',
      parentName: 'accountList',
      title: '详情'
    },
    component: () => import('@/views/accountManagement/accountList/detail')
  }
]
