/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/systemManagement/role',
    name: 'systemManagementRole',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '角色列表'
    },
    component: () => import('@/views/systemManagement/role/list')
  },
  // 设置角色员工
  {
    path: '/systemManagement/role/roleSetting',
    name: 'roleRoleSetting',
    meta: {
      requireAuth: false,
      group: '角色列表',
      parentName: 'systemManagementRole',
      title: '设置人员'
    },
    component: () => import('@/views/systemManagement/role/roleSetting')
  },
  // 设置角色菜单
  {
    path: '/systemManagement/role/setFunction',
    name: 'roleSetFunction',
    meta: {
      requireAuth: false,
      group: '角色列表',
      parentName: 'systemManagementRole',
      title: '设置菜单'
    },
    component: () => import('@/views/systemManagement/role/setFunction')
  },
  {
    path: '/systemManagement/employee',
    name: 'systemManagementEmployee',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '用户列表'
    },
    component: () => import('@/views/systemManagement/employee/list')
  },
  {
    path: '/systemManagement/employee/detail',
    name: 'systemManagementEmployeeDetail',
    meta: {
      requireAuth: false,
      group: '用户列表',
      parentName: 'systemManagementEmployee',
      title: '详情'
    },
    component: () => import('@/views/systemManagement/employee/edit')
  },
  {
    path: '/systemManagement/employee/:editMode',
    name: 'systemManagementEmployeeEdit',
    meta: {
      requireAuth: false,
      group: '用户列表',
      parentName: 'systemManagementEmployee',
      title: ''
    },
    component: () => import('@/views/systemManagement/employee/edit')
  },
  {
    // 组织架构管理
    path: '/systemManagement/org',
    name: 'systemManagementOrg',
    meta: {
      requireAuth: false,
      title: '组织架构列表'
    },
    component: () => import('@/views/systemManagement/org/list')
  },
  // 设置组织机构员工
  {
    path: '/systemManagement/org/orgSetting',
    name: 'orgSetting',
    meta: {
      requireAuth: false,
      group: '组织架构列表',
      parentName: 'systemManagementOrg',
      title: '人员设置'
    },
    component: () => import('@/views/systemManagement/org/orgSetting')
  },
  {
    // 消息配置
    path: '/systemManagement/news',
    name: 'news',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '消息配置管理'
    },
    component: () => import('@/views/systemManagement/news/list')
  },
  {
    path: '/systemManagement/news/detail',
    name: 'newsDetail',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'news',
      title: '详情'
    },
    component: () => import('@/views/systemManagement/news/edit')
  },
  {
    path: '/systemManagement/news/detail',
    name: 'newsInitDetail',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'news',
      title: '详情'
    },
    component: () => import('@/views/systemManagement/news/initEdit')
  },
  {
    path: '/systemManagement/news/:editMode',
    name: 'newsInitEdit',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'news',
      title: ''
    },
    component: () => import('@/views/systemManagement/news/initEdit')
  },
  {
    path: '/systemManagement/news/:editMode',
    name: 'newsEdit',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'news',
      title: ''
    },
    component: () => import('@/views/systemManagement/news/edit')
  },
  {
    // 操作日志
    path: '/systemManagement/workflow',
    name: 'workflowList',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '工作流管理'
    },
    component: () => import('@/views/systemManagement/workflow/list')
  },
  {
    path: '/systemManagement/workflow/config',
    name: 'systemWorkflowConfig',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'workflowList',
      title: '工作流配置'
    },
    component: () => import('@/views/systemManagement/workflow/config')
  },
  {
    path: '/systemManagement/workflow/edit',
    name: 'systemWorkflowEdit',
    meta: {
      requireAuth: false,
      group: '系统管理',
      parentName: 'workflowList',
      title: '工作流编辑'
    },
    component: () => import('@/views/systemManagement/workflow/edit')
  },
  {
    // 操作日志
    path: '/systemManagement/log',
    name: 'logList',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '操作日志'
    },
    component: () => import('@/views/systemManagement/log/list')
  },
  {
    path: '/systemManagement/archives',
    name: 'archives',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '电子档案管理'
    },
    component: () => import('@/views/systemManagement/archives/index')
  },
  {
    path: '/systemManagement/cert',
    name: 'cert',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '数字证书配置'
    },
    component: () => import('@/views/systemManagement/cert/index')
  },
  {
    path: '/systemManagement/configure',
    name: 'configure',
    meta: {
      requireAuth: false,
      group: '系统管理',
      title: '个性化配置'
    },
    component: () => import('@/views/systemManagement/configure/index')
  }
]
