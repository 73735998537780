/**
 * system Created by Huyangjing on 2022/01/14.
 */
export default [
  {
    // 服务费模板管理
    path: '/serviceChargeManagement/template',
    name: 'serviceChargeTemplateList',
    meta: {
      requireAuth: false,
      group: '服务费模型管理',
      title: '服务费模型'
    },
    component: () => import('@/views/serviceChargeManagement/template/list')
  },
  {
    // 添加服务费模板
    path: '/serviceChargeManagement/template/add',
    name: 'serviceChargeTemplateAdd',
    meta: {
      requireAuth: false,
      group: '服务费模型管理',
      parentName: 'serviceChargeTemplateList',
      title: '新增'
    },
    component: () => import('@/views/serviceChargeManagement/template/edit')
  },
  {
    // 修改服务费模板
    path: '/serviceChargeManagement/template/edit',
    name: 'serviceChargeTemplateEdit',
    meta: {
      requireAuth: false,
      group: '服务费模型管理',
      parentName: 'serviceChargeTemplateList',
      title: '修改'
    },
    component: () => import('@/views/serviceChargeManagement/template/edit')
  },
  {
    // 服务费模板详情
    path: '/serviceChargeManagement/template/detail',
    name: 'serviceChargeTemplateDetail',
    meta: {
      requireAuth: false,
      group: '服务费模型管理',
      parentName: 'serviceChargeTemplateList',
      title: '详情'
    },
    component: () => import('@/views/serviceChargeManagement/template/detail')
  },
  {
    // 服务费明细管理
    path: '/serviceChargeManagement/record',
    name: 'serviceChargeRecordList',
    meta: {
      requireAuth: false,
      group: '服务费模型管理',
      title: '服务费明细'
    },
    component: () => import('@/views/serviceChargeManagement/record/list')
  },
  {
    // 服务费明细详情
    path: '/serviceChargeManagement/record/detail',
    name: 'serviceChargeRecordDetail',
    meta: {
      requireAuth: false,
      group: '服务费模型管理',
      parentName: 'serviceChargeRecordList',
      title: '详情'
    },
    component: () => import('@/views/serviceChargeManagement/record/detail')
  }
]
