<!-- default -->
<template>
  <el-container class="default-layout" direction="vertical">
    <el-header class="header" height="48px">
      <h1 class="logo">
        <a href="/index" class="logo-title" />
      </h1>
      <div class="logo-subtitle" style="margin-top: -5px;">
        <router-link to="/index">
          {{ projectTitle }}
        </router-link>
      </div>
      <div name="account">
        <div class="account">
          <span class="welcome-msg">您好！{{ empName }}</span>
          <span style="padding-left: 0px;">
            <a class="account-link" href="javascript:;" @click="moreMessage">
              <span><i class="el-icon-bell" />消息</span>
            </a>
            <el-badge v-if="$store.state.quantity > 0" :value="$store.state.quantity" :max="99" class="item" />
          </span>
          <a class="account-link" href="javascript:;" @click="$router.push({ path: '/personal' })">
            <span><i class="el-icon-user" />个人中心</span>
          </a>
          <a class="account-link" href="javascript:;" @click="logout">
            <span>退出</span>
          </a>
        </div>
      </div>
    </el-header>
    <el-container class="main-container">
      <page-side :side-menu="this.$store.state.sideMenu" />
      <router-view />
    </el-container>
  </el-container>
</template>
<script>
import {cookie} from "../assets/js/config";

export default {
  name: 'DefaultLayout',
  components: {
  },
  data () {
    return {
      empName: this.$store.state.empName,
      projectTitle: '',
      invitationInfo: {},
      quantity: 0,
      sideMenu: [
        {
          menu: {
            menuType: '10',
            menuCode: '2',
            menuName: '授信管理'
          },
          childList: [{
            menu: {
              menuCode: '2-1',
              menuType: '20',
              menuUrl: '/creditManagement/list',
              menuName: '授信列表'
            }
          }]
        },
        {
          menu: {
            menuType: '10',
            menuCode: '2',
            menuName: '用信管理'
          },
          childList: [{
            menu: {
              menuCode: '2-1',
              menuType: '20',
              menuUrl: '/useManagement/list',
              menuName: '用信列表'
            }
          }]
        }
      ],
      consoleDomain: '',
      messageLink: ''
    }
  },
  watch: {
  },
  created () {
    if (!this.$store.state.sideMenu || this.$store.state.sideMenu.length === 0) {
      this.getMenu()
      this.projectTitle = cookie.globalWangName()
    }
    this.countMsg()
  },
  methods: {
    logout () {
      this.api.base.logOut().then(result => {
        this.$store.commit('removeEmpInfo')
        this.$store.commit('removeConfigureInfo')
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login'
        this.$message.success(result.data.message || '退出成功')
      })
    },
    getMenu () {
      this.api.base.getMenu().then(result => {
        this.$store.commit('updateSideMenu', result.data.data)
        // this.$store.commit('updateSideMenu', this.sideMenu)
        this.sideMenu = this.$store.state.sideMenu
        this.empName = this.$store.state.empName
        console.log()
        const childList = []
        result.data.data.forEach(item => {
          childList.push(item.childList)
        })
        if (!_.isEmpty(childList)) {
          const featureList = childList
          window.sessionStorage.setItem('powerFeatureList', JSON.stringify(featureList))
          this.$store.commit('updatePowerFeatureList', featureList)
        }
      })
    },
    countMsg () {
      this.api.workbench.countMsg().then(result => {
        this.$store.commit('updateQuantity', result.data.data)
      })
    },
    moreMessage () {
      this.$router.push({
        name: 'message'
      })
    }
  }
}
</script>
<style>
  .bicon-logo {
    width:80px !important;
    height:35px !important;
  }
  .header .account a:hover{
    cursor: pointer;
  }
  .welcome-msg {
    font-size: 14px;
    line-height: 14px;
    margin-left: 10px;
    color: #fff
  }
  .item {
    margin-top: -13px;
  }
</style>
