/**
 * system Created by HuZiqiang on 2022/5/17.
 */
export default [
  {
    path: '/payBackManagement',
    name: 'payBack',
    meta: {
      requireAuth: false,
      group: '还款管理',
      title: '还款列表'
    },
    component: () => import('@/views/payBackManagement/list')
  },
  //线下-单期
  {
    path: '/payBackManagement/offline/singleDetail',
    name: 'payOfflineSingleDetail',
    meta: {
      requireAuth: false,
      group: '还款管理',
      parentName: 'payBack',
      title: '详情'
    },
    component: () => import('@/views/payBackManagement/offline/single-detail')
  },
  //线下-多期
  {
    path: '/payBackManagement/offline/manyDetail',
    name: 'payOfflineManyDetail',
    meta: {
      requireAuth: false,
      group: '还款管理',
      parentName: 'payBack',
      title: '详情'
    },
    component: () => import('@/views/payBackManagement/offline/many-detail')
  },
  //线上-单期
  {
    path: '/payBackManagement/onLine/singleDetail',
    name: 'payOnLineSingleDetail',
    meta: {
      requireAuth: false,
      group: '还款管理',
      parentName: 'payBack',
      title: '详情'
    },
    component: () => import('@/views/payBackManagement/onLine/single-detail')
  },
  //线上-多期
  {
    path: '/payBackManagement/onLine/manyDetail',
    name: 'payOnLineManyDetail',
    meta: {
      requireAuth: false,
      group: '还款管理',
      parentName: 'payBack',
      title: '详情'
    },
    component: () => import('@/views/payBackManagement/onLine/many-detail')
  },
]
